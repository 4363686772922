import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom';
import { ReactComponent as Linkedin } from './icons/linkedin.svg';
import { ReactComponent as Github } from './icons/github.svg';
import { ReactComponent as HomeIcon } from './icons/home.svg';
import { ReactComponent as PortfolioIcon } from './icons/portfolio.svg';


import './App.scss';

function Navigation () {
  return (
    <div className='Navigation'>
      <div className='Menu'>
        <ul>
          <li>
            <NavLink to="/" exact>Home</NavLink>
          </li>
          {/* <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <NavLink to="/portfolio">Portfolio</NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

function MobileNavigation () {
  return (
    <div className='MobileNavigation'>
      <ul>
        <li>
          <NavLink to="/" exact><HomeIcon/></NavLink>
        </li>
        {/* <li>
          <NavLink to="/portfolio"><PortfolioIcon/></NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/about">About</NavLink>
        </li>
        */}
      </ul>
    </div>
  );
};

function Home() {
  return (
    <div className='VisitCard'>
      <div className='Avatar'>
        <div className='Image'></div>
      </div>
      <div className='Details'>
        <h3 className='Name'>Lyaysan</h3>
        <p className='Job'>Frontend developer based in Berlin.</p>
        <div className='SocialLinks'>
          <ul>
            <li>
              <a href='https://www.linkedin.com/in/lyaysan-biktimirova/' target='_blank'>
                <Linkedin/>
              </a>
            </li>
            <li>
              <a href='https://github.com/panda-go-panda' target='_blank'>
                <Github/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

function About() {
  return (
    <div className='AboutPage'>
      {/* <div className='AboutImageContainer'>
        <div className='AboutImage'></div>
      </div> */}
      <div className='Description'>
        <h3 className='AboutName'>Lyaysan Biktimirova</h3>
        <div className='Description'></div>
        <div className='Skills'></div>
      </div>
    </div>

  );
};

function Portfolio() {
  return (
    <div className='Portfolio'>
      <span>Portfolio</span>
      {/* <div className='List'>
        <div className='Project'></div>
        <div className='Project'></div>
        <div className='Project'></div>
        <div className='Project'></div>
      </div> */}
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className='App'>
        <Navigation />
        <MobileNavigation />
        <div className='Content'>
          <Switch>
            {/* <Route path='/about' children={({ match }) => (<div className={match ? "activeSection" : ""}><About /></div>)}>
            </Route> */}
            <Route path='/portfolio'>
              <Portfolio />
            </Route>
            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
